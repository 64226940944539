<template>
  <div class="px-4" id="favorite">
    <ul class="nav nav-pills mb-4 d-flex" id="pills-tab" role="tablist">
      <li class="nav-item col">
        <a class="nav-link px-0 active" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="true">
          <div class="title font-weight-bold h5 text-center m-0">حسابي</div>
        </a>
      </li>
      <li class="nav-item col">
        <a class="nav-link px-0" id="pills-settings-tab" data-toggle="pill" href="#pills-settings" role="tab" aria-controls="pills-settings" aria-selected="false">
          <div class="title font-weight-bold h5 text-center m-0">الإعدادات</div>
        </a>
      </li>
    </ul>

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
        <div class="row justify-content-md-center mx-0">
          
          <div v-if="meLoading" class="pt-3 mb-4">
            <ProfileInfoLoader />
          </div>

          <div class="title font-weight-bold h5 text-center my-4 mt-2">
            بيانات الحساب والإشتراك
          </div>

          <div class="row justify-content-md-center mx-0 px-3 pt-3 mb-5" v-if="me">
            <h5 class="mb-3"><i class="la la-phone me-3 la-lg text-brand"></i> {{ me.phone }}</h5>
            <h5 class="mb-3 d-flex d-md-block">
              <div class="d-flex flex-column justify-content-center d-md-inline-block">
                <i class="la la-user-check me-3 la-lg text-brand"></i> 
              </div>
              <small class="">مشترك منذ</small> {{ me.subscription_model.created_at }}
            </h5>
            <h5 class="mb-3"><i class="la la-mobile me-3 la-lg text-brand"></i> <small>حالة الإشتراك</small> 
              <span class="text-success ms-2" v-if="me.subscription_model.active_billing_until && new Date(me.subscription_model.active_billing_until) > (new Date())">
                نشط 
                <div class="card-secondary-lable text-sm mt-2 ps-5">حتى {{ me.subscription_model.active_billing_until }}</div>
              </span>
              <span class="text-danger ms-2" v-else>غير نشط</span>
            </h5>
          </div>

          <div class="text-center text-md-center py-5" v-if="me">
            <button @click="logout" class="btn bg-brand text-light shadow-sm mb-3" :disabled="unsubscriptionLoading">تسجيل الخروج</button>
            <div></div>
            <button @click="unsubscribe" class="btn main-card text-danger shadow-sm" :disabled="unsubscriptionLoading"><i class="la la-spinner la-spin me-2" v-if="unsubscriptionLoading"></i> إلغاء اﻹشتراك</button>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="pills-settings" role="tabpanel" aria-labelledby="pills-settings-tab">
        <div>
          <div class="title font-weight-bold h5 text-center my-4 pt-2 pb-1">
            إعدادات التفضيلات
          </div>
          <div class="text-white-50 text-sm mb-4 pb-1">
            هذه قائمة المحتوى الذي نعتقدأنه مفضل لديك، ويتم إقتراح المحتوى في الصفحة الرئيسية بناءً على هذه القائمة. يكنك دائماً تخصيصها كما تريد.
          </div>

          <div class="d-flex justify-content-md-center mx-0">
            
            <div v-if="!meLoading && me">
                <div v-for="preferableContent in userContentPreferences" :key="preferableContent.id" class="bg-brand ps-3 py-1 d-inline-block me-3 mb-3">
                  {{ preferableContent.preferableContent.name_ar }}

                  <i class="la la-times text-warning px-3 cursor-pointer" @click="removePreference(preferableContent.id)"></i>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import ProfileInfoLoader from '../../components/Loader/ProfileInfoLoader.vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router'
  import { useToast } from 'vue-toastification';
  import { ref } from '@vue/reactivity';

  export default {
    components: { ProfileInfoLoader },

    data: () => {
      return {
        operatorName: process.env.VUE_APP_PORTAL_OPERATOR,
      };
    },
  
    mounted() {
      this.profile()
    },

    setup() {

      const store = useStore()
      const router = useRouter()
      const toast = useToast()

      const unsubscriptionLoading = ref(false)
      const removePreferenceLoading = ref(false)
      const meLoading = ref(false)
      const userContentPreferences = ref([])

      const me = ref(null)

      const logout = () => {
        store.dispatch('LOGOUT_USER')
        store.commit("UPDATE_MINIMIZED_PLAYER_SHOW", false);
        store.commit('CLOSE_TRACKLIST', {})
        // router.push({ name: 'LoginInfo' })
        window.location.assign('/')
      }

      return {
        toast,
        logout,
        unsubscriptionLoading,
        removePreferenceLoading,
        userContentPreferences,
        meLoading,
        me,
      }
    },

    methods: {
      unsubscribe() {
        if(confirm('هل تريد إلغاء اﻹشتراك حقا؟')) {
          if (this.operatorName == "mondia") {
            this.unsubscribeMondiaUser()
          } else {
            this.unsubscribeUser()
          }
        }
      },

      profile() {
        this.meLoading = true
        this.$apollo.query({
          query: gql`
            query {
              me {
                id
                phone

                subscription_model {
                  active_billing_until
                  created_at
                }

                userContentPreferences {
                  id
                  user_id

                  preferableContent {
                    ... on Program {
                      id
                      name_ar
                      name_en
                    }
                    ... on Track {
                      id
                      name_ar
                      name_en
                    }
                  }
                }
              }
            }
          `,
        })
        .then((response) => {
          this.me = response.data.me
          this.userContentPreferences = this.me.userContentPreferences
          this.meLoading = false
        })
        .catch((error) => {
          this.meLoading = false
          this.reportError(error)
        })
      },

      unsubscribeUser() {
        this.unsubscriptionLoading = true
        this.$apollo
          .mutate({
            mutation: gql`
              mutation {
                unsubscribeUser {
                  message
                  code
                  success
                  payload
                }
              }
            `,
            // Parameters
            variables: {
              //
            },
          })
          .then((data) => {
            // Result
            console.log(data.data.unsubscribeUser)
            if(data.data.unsubscribeUser.success) {
              this.toast.success(data.data.unsubscribeUser.message)
              this.logout();
            }else {
              this.toast.error(data.data.unsubscribeUser.message)
            }
            this.unsubscriptionLoading = false;
          })
          .catch((error) => {
            console.log(error)
            this.unsubscriptionLoading = false
            this.reportError()
          });
      },

      unsubscribeMondiaUser() {
        this.unsubscriptionLoading = true
        this.$apollo
          .mutate({
            mutation: gql`
              mutation {
                unsubscribeMondiaUser {
                  message
                  code
                  success
                  payload
                }
              }
            `,
            // Parameters
            variables: {
              //
            },
          })
          .then((data) => {
            // Result
            console.log(data.data.unsubscribeMondiaUser)
            if(data.data.unsubscribeMondiaUser.success) {
              this.toast.success(data.data.unsubscribeMondiaUser.message)
              this.logout();
            }else {
              this.toast.error(data.data.unsubscribeMondiaUser.message)
            }
            this.unsubscriptionLoading = false;
          })
          .catch((error) => {
            console.log(error)
            this.unsubscriptionLoading = false
            this.reportError()
          });
      },

      removePreference(preferableContentId) {
        this.removePreferenceLoading = true
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!) {
                deleteContentPreference(id: $id) {
                  success
                  message
                }
              }
          `,
            // Parameters
            variables: {
              id: Number.parseInt(preferableContentId),
            },
          })
          .then((data) => {
            // Result
            // console.log(data.data.deleteContentPreference)
            this.userContentPreferences = this.userContentPreferences.filter(preference => preference.id != preferableContentId)
            this.removePreferenceLoading = false;
          })
          .catch((error) => {
            console.log(error)
            this.removePreferenceLoading = false
            this.reportError()
          });
      },
      reportError(error) {
        let { graphQLErrors } = error;

        if (graphQLErrors && graphQLErrors[0].extensions.category === "authentication") {
          this.toast.warning('إنتهت صلاحية الجلسة، الرجاء إعادة تسجيل الدخول')

          this.logout()
        }
      }
    },
  }
</script>

<style>

</style>