<template>
  <div class="search">
    <div class="search-result px-3">
      <div class="d-flex text-sm font-weight-bold h5 mb-4 mt-2 px-4">
        <div class="col mb-2 ">
          <div class="font-weight-bold">
            نتائج البحث عن 
            <span class="mx-1 text-success font-weight-normal">«{{ query }}»</span>
          </div>
        </div>
        
        <div class="text-center ">
          <select class="form-select bg-transparent border-0 text-center" v-model="selectedProgram">
            <option value="">اختر برنامج</option>
            <option v-for="program in programs" :key="program.id" :value="program.id">{{ program.name_ar }}</option>
          </select>
        </div>
      </div>
      <div class="row mx-0">

        <HorizontalCardLoader v-if="$apollo.queries.tracks.loading" />

        <div class="col-md-12" v-for="track in tracks.data ?? []" :key="track.id" v-if="!$apollo.queries.tracks.loading">
          <TrackHorizontalCard :track="track" :tracklist="tracks.data" />
        </div>

        <div class="col-md-12 text-center" v-if="!$apollo.queries.tracks.loading && !tracks.data.length">
          <div class="main-card p-5">
            لا توجد صوتيات هنا!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrackHorizontalCard from "../../components/TrackHorizontalCard.vue";
import gql from 'graphql-tag'
import HorizontalCardLoader from '../../components/Loader/HorizontalCardLoader.vue';

export default {
  name: "Home",
  components: {
    TrackHorizontalCard,
    HorizontalCardLoader,
  },

  data: () => {
    return {
      tracks: {},
      programs: {},
      selectedProgram: '',
      query: null,
    };
  },

  mounted () {
    this.query = this.$route.query.q
  },

  watch: {
    $route (to, from) {
      this.query = this.$route.query.q
    }
  },

  computed: {
    tracksQueryParams() {
      if (this.selectedProgram == '') {
        return {
          name_ar: this.query,
        };
      } else {
        return {
          name_ar: this.query,
          program_id: Number.parseInt(this.selectedProgram),
        };
      }
    },
  },

  methods: {
    // hasHistory() {
    //   return window.history.length > 2;
    // },
    // goBack() {
    //   if (this.hasHistory()) {
    //     this.$router.go(-1);
    //   } else {
    //     this.$router.push({ name: "Home" });
    //   }
    // },
  },

  apollo: {
    tracks: {
      query: gql`
        query ($name_ar: String, $program_id: ID) {
          tracks (name_ar: $name_ar, program_id: $program_id) {
            data {
              id
              name_ar
              name_en
              description_ar
              description_en
              duration_display
              thumbnail_url
              cover_url
              full_url

              authors {
                id
                name_ar
                name_en
              }

              categories {
                id
                name_ar
                name_en
              }
            }
          }
        }
      `,
      variables () {
        return this.tracksQueryParams;
      }
    },
    programs: {
      query: gql`
        query {
          programs {
            id
            name_ar
            name_en
          }
        }
      `
    },
  },
};
</script>
