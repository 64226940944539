<template>
    <!-- Add to playlist modal -->
    <div class="modal fade show d-block" id="updatePlaylistModal" tabindex="-1" role="dialog" aria-labelledby="updatePlaylistModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            تعديل قائمة التشغيل
          </div>
          <div class="modal-body">
                          
             <div class="playlist-item mb-2">
               <div class="d-flex">
                 <div class="col"><input type="text" v-model="playlistName" class="form-control form-control-sm" autofocus id="playlist-item"></div>
               </div>
             </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('close')">إلغاء</button>
            <button type="button" class="btn btn-brand" @click="onSave" :disabled="isLoading || !playlistName">
                <span v-if="isLoading" class="text-center"><i class="la la-spin la-spinner"></i></span>
                حفظ
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import gql from "graphql-tag"
import { useToast } from 'vue-toastification'

export default {
    props: ['playlist'],

    setup() {
        const toast = useToast();

        return {
            toast
        }
    },

    data() {
        return {
            newPlaylistInputIsActive: true,
            playlistName: '',
            isLoading: false,
        }
    },

    mounted () {
      this.playlistName = this.playlist.name
    },

    methods: {
        onSave() {

            this.isLoading = true
            this.$apollo.mutate({
                mutation: gql`
                    mutation ($playlistID: ID!, $input: PlaylistInput!) {
                        updatePlaylist(id: $playlistID, input: $input) {
                            id
                            name
                        }
                    }
                `,
                // Parameters
                variables: {
                    playlistID: this.playlist.id,
                    input: {
                        name: this.playlistName
                    },
                },
            })
            .then((res) => {
                // Result
                console.log(res.data.updatePlaylist)
                this.toast.success('تم حفظ التعديلات')
                // Rerender Component
                // vm.$forceUpdate();
                this.$emit('close')
            }).catch((error) => {
                // Error
                console.error(error)
                this.isLoading = false
            });
        },
    },
}
</script>

<style>

</style>