<template>
  <div class="login">
    <div class="cover-container">
      <div class="cover py-5 bg-brand">
        <img src="@/assets/images/logos/logo.png" alt="" class="" />
      </div>
    </div>

    <div class="px-4">
      <div class="text-sm font-weight-bold text-center">
        <div><i class="la la-check-circle la-6x text-brand"></i></div>
        <div class="font-weight-bold h5 my-4 px-3 mt-3 text-brand">تم اﻹشتراك بخدمة زيتون</div>
      </div>

      <div>
        <div class="">
          <div class="text-center px-2 mt-4">
            <small>مقابل 5 جنيه يجدد كل يوم. لإلغاء اﻹشتراك برجاء إرسال [كلمة إلغاء] إلى "رقم المختصر" او زيارة الصفحة الرئيسية لموقع الخدمة.</small>
          </div>
        </div>
        <div class="text-center px-4 py-3">
          <router-link :to="{ name: 'Home' }" class="btn btn-success font-weight-bold mx-1 pt-2" >الرجوع للصفحة الرئيسية</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { useToast } from 'vue-toastification'
import gql from 'graphql-tag';

export default {
  components: {},

  setup () {

    const toast = useToast()

    let subscriptionLoading = ref(false)
    let otpVerificationLoading = ref(false)
    let isOTPSent = ref(false)

    let phone = ref('')
    let key = ref('20')
    let requestId = ref('')

    let num1 = ref('')
    let num2 = ref('')
    let num3 = ref('')
    let num4 = ref('')

    let full_phone = computed(() => {
      return ""+ key.value + "" + phone.value;
    })

    let otp = computed(() => {
      return ""+ num1.value + "" + num2.value + "" + num3.value + "" + num4.value;
    })

    function clickEvent(first,last) {
			if(first.target.value.length && !isNaN(first.target.value)){
				document.getElementById(last).focus();
			} else {
        first.target.value = '';
      }
		} 

    watchEffect(() => {
      if (otp.value.length === 4) {
        console.log("Done ", otp.value)
      }
    })

    return {
      toast,
      clickEvent,
      subscriptionLoading,
      otpVerificationLoading,
      phone,
      key,
      num1,
      num2,
      num3,
      num4,
      otp,
      full_phone,
      isOTPSent,
      requestId,
    }
  },

  methods: {
    subscribeUser() {
      this.subscriptionLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: SubscribeUserInput!) {
              subscribeUser(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              phone: this.full_phone,
            }
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.subscribeUser)
          if(data.data.subscribeUser.success) {
            if(data.data.subscribeUser.code === 201) {
              this.$store.commit('SET_TOKEN', data.data.subscribeUser.payload)
              this.toast.success(data.data.subscribeUser.message)
              this.$router.push({ name: 'Home' })
            } else {
              this.isOTPSent = true
              this.requestId = data.data.subscribeUser.payload
              this.toast.success(data.data.subscribeUser.message)
              setTimeout(() => {
                  this.$refs.ist.focus();
              }, 500);
            }
          }else {
            this.toast.error(data.data.subscribeUser.message)
          }
          this.subscriptionLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.subscriptionLoading = false

          this.reportError(error)
        });
    },

    verifyOTP() {
      this.otpVerificationLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: VerifyOTPInput!) {
              verifyOTP(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              phone: this.full_phone,
              requestId: this.requestId,
              otp: this.otp,
            }
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.verifyOTP)
          if(data.data.verifyOTP.success) {
            this.$store.commit('SET_TOKEN', data.data.verifyOTP.payload)
            this.toast.success('تم اﻹشتراك بنجاح')
            this.$router.push({ name: 'Home' })
          } else {
            this.toast.success(data.data.verifyOTP.message)
          }
          this.otpVerificationLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.otpVerificationLoading = false

          this.reportError(error)
        });
    },

    reportError(error) {
      let { graphQLErrors } = error;
      if (graphQLErrors[0].extensions.category === "validation") {
          let validationErrors = graphQLErrors[0].extensions.validation;
          for (const [key, value] of Object.entries(validationErrors)) {
            this.toast.error(value[0])
          }
          
          this.toast.error('خطأ في البيانات')
      }
    }
  }

};
</script>


<style>

  .phone, .key {
    letter-spacing: .5rem;
  }


  /* input.form-control {
    text-align: center;
    height: 60px;
  } */
  
</style>