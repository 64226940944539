<template>
  <div class="playlist px-2 px-md-2">
    <div class="text-sm font-weight-bold h5 mb-3 mt-2 d-flex" v-if="!$apollo.queries.playlist.loading">
      <span class="col font-weight-bold card-secondary-lable h5 mt-2 px-2 d-flex">
        <i class="la la-file-audio card-title me-2"></i> <span>{{ playlist?.name }}</span></span>
      <div class="text-center font-weight-normal">

        <span class="cursor-pointer" @click="updatePlaylistModalShow++">
            <span class="fs-1 p-1"><i class="ripple ripple-surface la la-edit card-secondary-lable me-1"></i></span>
        </span>

         <span class="cursor-pointer" @click="deletePlaylist">
            <span class="fs-1 p-1"><i class="ripple ripple-surface la la-trash-alt card-secondary-lable me-1"></i></span>
        </span>

        <UpdatePlaylistModal :playlist="playlist" :key="updatePlaylistModalShow" v-if="updatePlaylistModalShow" @close="updatePlaylistModalShow = 0" />

      </div>
      <!-- <div class="text-center pt-1 float-end font-weight-normal">
        <small class="font-weight-bold mb-1 d-block">المدة بالكامل</small>
        <small class="text-success d-block">13:01:33</small>
      </div> -->
    </div>
    <div class="row mx-0">
        <HorizontalCardLoader v-if="$apollo.queries.playlist.loading" />

        <div class="col-md-12" v-for="track in playlist.tracks" :key="track.id">
          <TrackHorizontalCard :track="track" :tracklist="playlist.tracks" :playlist-name="playlist.name" />
        </div>

        <div class="col-md-12 text-center" v-if="!$apollo.queries.playlist.loading && !playlist.tracks.length">
          <div class="main-card p-5">
            لا توجد صوتيات هنا!
          </div>
        </div>
      </div>
  </div>

</template>

<script>
import TrackHorizontalCard from "@/components/TrackHorizontalCard.vue";
import gql from 'graphql-tag'
import HorizontalCardLoader from '../../components/Loader/HorizontalCardLoader.vue';
import UpdatePlaylistModal from '../../components/Player/playlist/UpdatePlaylistModal.vue';
import { useToast } from 'vue-toastification';


export default {
  components: {
    TrackHorizontalCard,
    HorizontalCardLoader,
    UpdatePlaylistModal,
  },

  setup() {
    const toast = useToast()

    return {
      toast
    }
  },

  data: () => {
    return {
      playlist: {},
      updatePlaylistModalShow: 0,
      deletePlaylistLoading: false,
    };
  },

  methods: {

    deletePlaylist() {
      if(!confirm('هل تريد حذف قائمة التشغيل؟')) {
        return
      }

      this.deletePlaylistLoading = true
      this.$apollo.mutate({
          mutation: gql`
              mutation ($playlistID: ID!) {
                  deletePlaylist(id: $playlistID) {
                      id
                      name
                  }
              }
          `,
          // Parameters
          variables: {
              playlistID: this.playlist.id,
          },
      })
      .then((res) => {
          // Result
          console.log(res.data.deletePlaylist)
          this.toast.success('تم حذف قائمة التشغيل')
          this.$router.back()
          // Rerender Component
          // vm.$forceUpdate();
          this.$emit('close')
      }).catch((error) => {
          // Error
          console.error(error)
          this.deletePlaylistLoading = false
      });

    }
  },

  apollo: {
    playlist: {
      query: gql`
        query Provider($id: ID) {
          playlist (id: $id) {
            id
            name

            tracks {
              id
              name_ar
              name_en
              description_ar
              description_en
              thumbnail_url
              cover_url
              full_url

              authors {
                id
                name_ar
                name_en
              }

              categories {
                id
                name_ar
                name_en
              }
            }
          }
        }
      `,
      variables () {
        return {
          id: this.$route.params.id
        }
      }
    },
  },
};
</script>

<style></style>
