<template>
  <div class="home py-5">

    <div class="loading" v-if="$apollo.queries.appearanceLayout.loading">
      <div class="pt-4">
        <div class="vertical-scroll d-flex hide-scrollbar ps-4">
          <VerticalCardLoader />
        </div>
      </div>
      <div class="pt-4">
        <div class="vertical-scroll d-flex hide-scrollbar ps-4">
          <CategoryCardLoader />
        </div>
      </div>
      <div class="pt-4">
        <div class="px-4">
            <HorizontalCardLoader />
        </div>
      </div>
      <div class="pt-4">
        <div class="vertical-scroll d-flex hide-scrollbar ps-4">
          <VerticalCardLoader />
        </div>
      </div>
    </div>

    <RecommendedSection />


    <div v-if="!$apollo.queries.appearanceLayout.loading">
      <div v-for="collection in appearanceLayout.appearanceLayoutItems" :key="collection.id">
        <CollectionSection :section="collection" />
      </div>
    </div>

  </div>
</template>

<script>
import gql from "graphql-tag";

import CollectionSection from '../../components/CollectionSection.vue';
import VerticalCardLoader from '../../components/Loader/VerticalCardLoader.vue';
import CategoryCardLoader from '../../components/Loader/CategoryCardLoader.vue';
import HorizontalCardLoader from '../../components/Loader/HorizontalCardLoader.vue';
import RecommendedSection from "../../components/RecommendedSection.vue";

export default {
  name: "Home",
  components: {
    CollectionSection,
    VerticalCardLoader,
    CategoryCardLoader,
    HorizontalCardLoader,
    RecommendedSection,
  },

  data() {
    return {
      appearanceLayout: {},
      loginLoading: false,
      full_phone: '',
      operatorName: process.env.VUE_APP_PORTAL_OPERATOR,
    }
  },

  created() {
    // this.$apollo.queries.tracks.skip = true
  },

  mounted() {
    this.checkPhoneNumber()

    if(this.operatorName === 'mondia') {
      this.handleMondiaRedirect()
    }
  },

  methods: {    
    checkPhoneNumber () {
      let phone = this.$route.query.phoneNumber
      if(phone && phone.length > 8) {
        if(phone.length == 8) {
          this.full_phone = `2491${phone}`
        }
        if(phone.length == 9) {
          this.full_phone = `249${phone}`
        }
        if(phone.length == 10) {
          tphone = phone.slice(1)
          this.full_phone = `249${phone}`
        }
        if(phone.length == 12) {
          this.full_phone = phone
        }
      }
      // console.log('token', this.$store.getters.getToken)
      // console.log('full_phone', this.full_phone)
      if (this.full_phone.length === 12 && this.$store.getters.getToken === null){
        this.loginUser()
      }
    },

    loginUser() {
      this.loginLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: LoginUserInput!) {
              loginUser(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              phone: this.full_phone,
            }
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.loginUser)
          if(data.data.loginUser.success) {
            if(data.data.loginUser.code === 201) {
              this.$store.commit('SET_TOKEN', data.data.loginUser.payload)
              // this.toast.success(data.data.loginUser.message)
              console.log(data.data.loginUser.message)
              this.$router.push({ name: 'Home' })
            } else {
              this.isOTPSent = true
              this.requestId = data.data.loginUser.payload
              // this.toast.success(data.data.loginUser.message)
              console.log(data.data.loginUser.message)
            }
          }else {
            // this.toast.error(data.data.loginUser.message)
            console.log(data.data.loginUser.message)
          }
          this.loginLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loginLoading = false
        });
    },

    handleMondiaRedirect () {
      let code = this.$route.query.code
      let status = this.$route.query.status
      let uuid = this.$route.query.uuid
      let message = this.$route.query.message

      if(status === '200' && code && message === 'OK') {
        this.loginMondiaUser(code, uuid)
      }
    },

    loginMondiaUser(code, uuid) {
      this.loginLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: LoginMondiaUserInput!) {
              loginMondiaUser(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              code: code,
              uuid: uuid,
            }
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.loginMondiaUser)
          if(data.data.loginMondiaUser.success) {
            this.$store.commit('SET_TOKEN', data.data.loginMondiaUser.payload)
            // this.toast.success(data.data.loginMondiaUser.message)
            console.log(data.data.loginMondiaUser.message)
            this.$router.push({ name: 'Home' })
          }else {
            // this.toast.error(data.data.loginMondiaUser.message)
            console.log(data.data.loginMondiaUser.message)
          }
          this.loginLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.loginLoading = false
        });
    },
  },

  apollo: {
    appearanceLayout: {
      query: gql`
        query  {
          appearanceLayout (slug: "settings_appearance_homepage_layout") {
            slug
            appearanceLayoutItems {
              id
              slug
              title
              content_type
              layout
            }
          }
        }
      `,
    },
  },
};
</script>
