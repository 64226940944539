<template>
  <div class="login">
    <div class="cover-container">
      <div class="">
        <img src="https://api.zaytoon-base.com/storage/program/covers/wCxmjDVrkdyKT53xz6XOqA6BArhmaDAu4nuBcfFT.jpg" alt="" class="w-100 position-relative" style="height: 210px; object-fit: cover; top: -16px" />
      </div>
    </div>

    <div class="px-4">
      <div class="text-sm font-weight-bold text-center">
        <strong class="font-weight-bolder h3 my-4 px-3 mt-0 text-brand">زيتون</strong>
      </div>
      
      <div v-if="isOTPSent">
        <div class="">
          <div class="text-center px-5 my-3">
            <div class="font-weight-bold text-sm">تم إرسال الرمز لرقم هاتفك</div>
            <div class="font-weight-bold text-brand mt-3 text-sm">الرجاء إدخال رمز التأكيد</div>
          </div>
          <div dir="ltr">
            <div class="d-flex justify-content-center px-5">
              <input type="text" class="form-control font-weight-bold text-center mx-1 py-2" autofocus v-model="num1" id='ist' ref="ist" maxlength="1" @input="clickEvent($event,'sec')"  placeholder="_">
              <input type="text" class="form-control font-weight-bold text-center mx-1 py-2" v-model="num2" id="sec" maxlength="1" @input="clickEvent($event,'third')" placeholder="_">
              <input type="text" class="form-control font-weight-bold text-center mx-1 py-2" v-model="num3" id="third" maxlength="1" @input="clickEvent($event,'fourth')" placeholder="_">
              <input type="text" class="form-control font-weight-bold text-center mx-1 py-2" v-model="num4" id="fourth" maxlength="1" @input="clickEvent($event,'fifth')" placeholder="_">
              <input type="hidden" class="" id="fifth" maxlength="1">
            </div>
          </div>
        </div>
        <div class="text-center p-4">
          <button class="btn btn-success mx-1 mb-2" @click="confirmSubscription" :disabled="otp.length < 4 || otpVerificationLoading"><i class="la la-spinner la-spin me-2" v-if="otpVerificationLoading"></i> تأكيد اﻹشتراك</button>
        </div>
      </div>

      <div v-else>
        <div class="">
          <div class="text-center px-2 mt-4">
            <small>زيتون هي خدمة تمكنك من الدخول في جو الروحانيات واﻹستماع إلى العديد من المقاطع الدينية والتلاوات الخاشعة، بإشتراك يومي. الخدمة ستكون مجاناً لمدة يوم واحد.</small>
          </div>
          <div class="text-center px-2 mb-3 font-weight-bold text-brand">
            <small>إدخل رقم هاتفك ثم إضغط على اشترك اﻵن</small>
          </div>
          <div>
            <div class="d-flex justify-content-center px-4">
              <div class="col">
                <input type="text" class="form-control font-weight-bold text-center rounded-0 rounded-start mx-1 py-2 px-0 phone" v-model="phone" maxlength="10" placeholder="1234567899">
              </div>
              <div class="w-25">
                <input type="text" class="form-control font-weight-bold text-center rounded-0 rounded-end border-start-0 mx-1 py-2 ps-0 key" v-model="key" maxlength="2">
              </div>
            </div>
          </div>
        </div>
        <div class="text-center px-4 py-3">
          <button class="btn btn-success font-weight-bold mx-1 pt-2" @click="subscribeUser" :disabled="phone.length < 10 || subscriptionLoading"><i class="la la-spinner la-spin me-2" v-if="subscriptionLoading"></i> اشترك اﻵن</button>
        </div>
        <div class="px-3 mt-2 text-sm">
            <div class="font-weight-bold card-secondary-lable mb-2">بالضغط على اشترك اﻵن فأنت توافق على الشروط التالية:</div>
            <ul class="px-2">
              <li class="">بعد إنتهاء الفترة المجانية، سيتم محاسبتك 5 جنيه كل يوم على فاتورة هاتفك المحمول وسيتم تجديد اﻹشتراك تلقائياً.</li>
              <li class="">سوف يتم خصم استهلاك اﻹنترنت من الباقة الخاصة بك.</li>
              <li class="">سوف تستمتع بالخدمة مجاناً لمدة يوم واحد بدون أي رسوم.</li>
              <li class="">يمكنك إلغاء اإلشتراك من الخدمة عن طريق زيارة موقع الخدمه او ارسال <br>STOP zaytoon-base<br> لرقم 7786 لمستخدمي إتصالات
                <br>لرقم 7810 لمستخدمي أورانج
                <br>لرقم 7785 لمستخدمي فودافون
                <br>لرقم 4036 لمستخدمي وي.
              </li>
              <li class="">تأكيد أيضا أن عمرك 18 سنة أو أكثر وأنك صاحب هذا المحمول أو لديك موافقة من صاحب المحمول.</li>
              <li class="">للحصول على المساعدة يرجى ارسال بريد إلكتروني إلى info@zaytoon-base.com</li>
            </ul>
            <div class="text-center">
              <a href="https://sites.google.com/view/zaytoon-base/home" class="text-brand font-weight-bold">سياسة الخصوصية</a>
            </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { useToast } from 'vue-toastification'
import gql from 'graphql-tag';

export default {
  components: {},

  setup () {

    const toast = useToast()

    let subscriptionLoading = ref(false)
    let otpVerificationLoading = ref(false)
    let isOTPSent = ref(false)

    let phone = ref('')
    let key = ref('20')
    let purchaseToken = ref('')

    let num1 = ref('')
    let num2 = ref('')
    let num3 = ref('')
    let num4 = ref('')

    let full_phone = computed(() => {
      return ""+ key.value + "" + phone.value;
    })

    let otp = computed(() => {
      return ""+ num1.value + "" + num2.value + "" + num3.value + "" + num4.value;
    })

    function clickEvent(first,last) {
			if(first.target.value.length && !isNaN(first.target.value)){
				document.getElementById(last).focus();
			} else {
        first.target.value = '';
      }
		} 

    // watchEffect(() => {
    //   if (otp.value.length === 4) {
    //     console.log("Done ", otp.value)
    //   }
    // })

    return {
      toast,
      clickEvent,
      subscriptionLoading,
      otpVerificationLoading,
      phone,
      key,
      num1,
      num2,
      num3,
      num4,
      otp,
      full_phone,
      isOTPSent,
      purchaseToken,
    }
  },

  methods: {
    subscribeUser() {
      this.subscriptionLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: CreateSubscriptionInput!) {
              createSubscription(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              phone: this.full_phone,
            }
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.createSubscription)
          if(data.data.createSubscription.success) {
              this.isOTPSent = true
              this.purchaseToken = data.data.createSubscription.payload
              this.toast.success(data.data.createSubscription.message)
              setTimeout(() => {
                  this.$refs.ist.focus();
              }, 500);
          }else {
            this.toast.error(data.data.createSubscription.message)
          }
          this.subscriptionLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.subscriptionLoading = false

          this.reportError(error)
        });
    },

    confirmSubscription() {
      this.otpVerificationLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: ConfirmSubscriptionInput!) {
              confirmSubscription(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              phone: this.full_phone,
              purchaseToken: this.purchaseToken,
              pin: this.otp,
            }
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.confirmSubscription)
          if(data.data.confirmSubscription.success) {
            this.$store.commit('SET_TOKEN', data.data.confirmSubscription.payload)
            this.toast.success('تم اﻹشتراك بنجاح')
            this.$router.push({ name: 'MondiaOTPFlowSuccess' })
          } else {
            this.toast.error(data.data.confirmSubscription.message)
          }
          this.otpVerificationLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.otpVerificationLoading = false

          this.reportError(error)
        });
    },

    reportError(error) {
      let { graphQLErrors } = error;
      if (graphQLErrors[0].extensions.category === "validation") {
          let validationErrors = graphQLErrors[0].extensions.validation;
          for (const [key, value] of Object.entries(validationErrors)) {
            this.toast.error(value[0])
          }
          
          this.toast.error('خطأ في البيانات')
      }
    }
  }

};
</script>


<style>

  .phone, .key {
    letter-spacing: .5rem;
  }


  /* input.form-control {
    text-align: center;
    height: 60px;
  } */
  
</style>