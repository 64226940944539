<template>
  <!-- <router-link :to="{ name: 'Category', params: { id: category.id } }"> -->
    
    <div class="collection">
      <div class="title font-weight-bold h6 my-3 mt-2 px-4">
        مخصص لك
        <!-- <router-link :to="{ name: 'Category', params: { id: categories[0].id } }" v-if="categories.length" class="text-success float-end font-weight-normal" -->
          <!-- ><small>مشاهدة المزيد</small></router-link> -->
      </div>

    </div>

    <div class="mb-5">
      <div v-if="$apollo.queries.recommendedSection.loading">
    
          <div class="vertical-scroll d-flex hide-scrollbar ps-4" >
            <VerticalCardLoader />
          </div>

      </div>

      <div v-else>
        <div class="vertical-scroll d-flex hide-scrollbar ps-2 pb-3">
          <div class="" v-for="track in recommendedSection.tracks" :key="track.id">
            <TrackVerticalCard :track="track" :tracklist="recommendedSection.tracks" />
          </div>
        </div>
      </div>
    </div>
  <!-- </router-link> -->
</template>

<script>
import gql from "graphql-tag";
import HorizontalCardLoader from './Loader/HorizontalCardLoader.vue';
import VerticalCardLoader from './Loader/VerticalCardLoader.vue';
import TrackHorizontalCard from './TrackHorizontalCard.vue';
import TrackVerticalCard from './TrackVerticalCard.vue';

export default {
  components: { HorizontalCardLoader, VerticalCardLoader, TrackHorizontalCard, TrackVerticalCard },
  props: [],

  data: () => {
    return {
      recommendedSection: {},
    };
  },

  apollo: {
    recommendedSection: {
      query: gql`
        query {
          recommendedSection (first: 10) {
            count
            hasMorePages
            currentPage

            tracks {
              id
              name_ar
              description_ar
              description_en
              thumbnail_url
              cover_url
              full_url

              authors {
                id
                name_ar
                name_en
              }

              categories {
                id
                name_ar
              }
            }
          }
        }
      `,
      variables () {   
        return {
        }
      },
    },
  }
};
</script>

<style></style>
